








































































































import { Component, Prop, Vue } from "vue-property-decorator";
import CartModule from "@/store/modules/cart";
import AlertModule, { AlertType } from "@/store/modules/alert";
import ArticlePriceLabel from "@/components/shop/article/price/ArticlePriceLabel.vue";
import { Article } from "@/types/shop/article";

/**
 * ArticleListItem component
 *
 * @author Kevin Danne <danne@skiba-procomputer.de>
 */
@Component({
    components: {
        ArticlePriceLabel,
    },
})
export default class ArticleListItem extends Vue {
    @Prop({ type: Object, required: true }) readonly article!: Article;
    @Prop({ type: Boolean, default: false })
    readonly showProperties!: boolean;
    @Prop({ type: Boolean, default: false })
    readonly showAddToCartButton!: boolean;
    @Prop({ type: String, default: "100%" })
    readonly height!: string;
    @Prop({ type: String, default: "100%" }) readonly width!: string;

    private quantity = 1;

    private addToCart() {
        CartModule.addArticle({
            commissionId: -1,
            commissionName: null,
            article: this.article,
            quantity: this.quantity,
        })
            .then(() => {
                this.$router.push({
                    name: "shoppingCart",
                });
            })
            .catch((err) => {
                const errorMessage =
                    err instanceof Error ? err.message : (err as string);

                AlertModule.showAlert({
                    type: AlertType.ERROR,
                    message: errorMessage,
                });
            });
    }
}
