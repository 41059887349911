import {
    VuexModule,
    Module,
    getModule,
    config,
    Mutation,
    Action,
} from "vuex-module-decorators";
import store from "@/store/";
import AuthModule from "@/store/modules/auth";
import axios, { APIResponse } from "@/plugins/axios";
import {
    Cart,
    CartCommission,
    CartCommissionItem,
    CartArticle,
} from "./types/";

// Set rawError to true for all @Action
config.rawError = true;

/**
 * VuexModule for shopping cart
 *
 * @author Kevin Danne <danne@skiba-procomputer.de>
 */
@Module({ store: store, namespaced: true, name: "cart", dynamic: true })
class CartModule<ArticleType extends CartArticle> extends VuexModule {
    // Property of type Cart
    private _cart: Cart<ArticleType> = localStorage.getItem("cart")
        ? JSON.parse(localStorage.getItem("cart") as string)
        : {
              commissions: [],
          };

    /**
     * Add commission to local cart
     *
     * @param commission commission
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    @Mutation
    private addCommissionToLocalCart(commission: CartCommission<ArticleType>) {
        this._cart.commissions.push(commission);
    }

    /**
     * Add commission item to local cart
     *
     * @param payload object with commissionId and commissionItem
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    @Mutation
    private addCommissionItemToLocalCart(payload: {
        commissionId: number;
        commissionItem: CartCommissionItem<ArticleType>;
    }) {
        const commissionIndex = this._cart.commissions.findIndex(
            (c) => c.id === payload.commissionId
        );
        if (commissionIndex === -1) return;

        this._cart.commissions[commissionIndex].items.push(
            payload.commissionItem
        );
    }

    /**
     * Update commission item in local cart
     *
     * @param payload object with commissionId, articleId and quantity
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    @Mutation
    private updateCommissionItemQuantityInLocalCart(payload: {
        commissionId: number;
        articleId: number;
        quantity: number;
    }) {
        const commissionIndex = this._cart.commissions.findIndex(
            (c) => c.id === payload.commissionId
        );
        if (commissionIndex === -1) return;

        const commissionItemIndex = this._cart.commissions[
            commissionIndex
        ].items.findIndex((ci) => ci.article.id === payload.articleId);
        if (commissionItemIndex === -1) return;

        // Update commission item
        this._cart.commissions[commissionIndex].items[
            commissionItemIndex
        ].quantity = payload.quantity;
    }

    /**
     * Remove article from local cart
     *
     * @param payload object with commissionId and articleId
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    @Mutation
    private removeArticleFromLocalCart(payload: {
        commissionId: number;
        articleId: number;
    }) {
        const commissionIndex = this._cart.commissions.findIndex(
            (c) => c.id === payload.commissionId
        );
        if (commissionIndex === -1) return;

        const commissionItemIndex = this._cart.commissions[
            commissionIndex
        ].items.findIndex((ci) => ci.article.id === payload.articleId);
        if (commissionItemIndex === -1) return;

        // remove commission item
        this._cart.commissions[commissionIndex].items.splice(
            commissionItemIndex,
            1
        );

        // remove commission when last item was deleted
        if (this._cart.commissions[commissionIndex].items.length === 0) {
            this._cart.commissions.splice(commissionIndex, 1);
        }
    }

    /**
     * Remove commission from local cart
     *
     * @param commissionId commission id
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    @Mutation
    private removeCommissionFromLocalCart(commissionId: number) {
        const commissionIndex = this._cart.commissions.findIndex(
            (c) => c.id === commissionId
        );
        if (commissionIndex === -1) return;

        this._cart.commissions.splice(commissionIndex, 1);
    }

    /**
     * Saves cart in localStorage
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    @Action
    public async saveCart() {
        localStorage.setItem("cart", JSON.stringify(this._cart));
    }

    /**
     * Adds the given commission to the cart
     * When the user is authenticated the article will be added to online cart
     * Else the article will be added to offline cart
     *
     * @param payload object with name, itms and quantity
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    @Action
    public async addCommission(payload: {
        name: string | null;
        items: CartCommissionItem<ArticleType>[];
        quantity: number;
    }) {
        const newCommission: CartCommission<ArticleType> = {
            id: -1,
            name: payload.name,
            items: payload.items.map((item) => ({
                ...item,
                quantity: item.quantity * payload.quantity,
            })),
        };

        if (!AuthModule.isAuthenticated) {
            this.addCommissionToLocalCart(newCommission);
            this.saveCart();
        } else {
            const response = await axios.post<
                APIResponse<CartCommission<ArticleType>>
            >("/shoppingcart/group/add", newCommission);
            if (response.data.status === "error") {
                throw new Error(response.data.message || "unknownError");
            }
        }
    }

    /**
     * Adds the given article to the cart
     * When a commission with same article already exists the quantity will be updated
     * Else a new commission will be added
     *
     * When the user is authenticated the article will be added to online cart
     * Else the article will be added to offline cart
     *
     * @param payload object with commissionId, commissionName, article and quantity
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    @Action
    public async addArticle(payload: {
        commissionId: number;
        commissionName: string | null;
        article: ArticleType;
        quantity: number;
    }) {
        const existingCommissionIndex = this._cart.commissions.findIndex(
            (c) => c.id === payload.commissionId
        );

        if (existingCommissionIndex !== -1) {
            const existingCommissionItemIndex = this._cart.commissions[
                existingCommissionIndex
            ].items.findIndex((ci) => ci.article.id === payload.article.id);

            // Add aritlce to existing commission when commission exists without given article id
            if (existingCommissionItemIndex === -1) {
                const newCommissionItem: CartCommissionItem<ArticleType> = {
                    article: payload.article,
                    quantity: payload.quantity,
                };

                if (!AuthModule.isAuthenticated) {
                    this.addCommissionItemToLocalCart({
                        commissionId: payload.commissionId,
                        commissionItem: newCommissionItem,
                    });
                    this.saveCart();
                } else {
                    const response = await axios.post<
                        APIResponse<CartCommission<ArticleType>>
                    >("/shoppingcart/add", newCommissionItem);
                    if (response.data.status === "error") {
                        throw new Error(
                            response.data.message || "unknownError"
                        );
                    }
                }
                // Update article quantity when commission with same article already exists
            } else {
                this.updateArticleQuantity({
                    commissionId: payload.commissionId,
                    articleId: payload.article.id,
                    quantity:
                        this._cart.commissions[existingCommissionIndex].items[
                            existingCommissionItemIndex
                        ].quantity + payload.quantity,
                });
                this.saveCart();
            }
            // Add new commission when not existing
        } else {
            const newCommission: CartCommission<ArticleType> = {
                id: -1,
                name: payload.commissionName,
                items: [
                    { article: payload.article, quantity: payload.quantity },
                ],
            };

            if (!AuthModule.isAuthenticated) {
                this.addCommissionToLocalCart(newCommission);
                this.saveCart();
            } else {
                const response = await axios.post<
                    APIResponse<CartCommission<ArticleType>>
                >("/shoppingcart/add", newCommission);
                if (response.data.status === "error") {
                    throw new Error(response.data.message || "unknownError");
                }
            }
        }
    }

    /**
     * Update the given article quantity in the cart
     * When the user is authenticated the commission item will be updated in online cart
     * Else the commission item will be updated in offline cart
     *
     * @param payload object with commissionId, articleId and quantity
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    @Action
    public async updateArticleQuantity(payload: {
        commissionId: number;
        articleId: number;
        quantity: number;
    }) {
        if (!AuthModule.isAuthenticated) {
            this.updateCommissionItemQuantityInLocalCart(payload);
            this.saveCart();
        } else {
            const response = await axios.post<
                APIResponse<CartCommission<ArticleType>>
            >("/shoppingcart/update", payload);

            if (response.data.status === "error") {
                throw new Error(response.data.message || "unknownError");
            }
        }
    }

    /**
     * Removes the given article from the cart
     * When the user is authenticated the article will be removed from online cart
     * Else the article will be removed from offline cart
     *
     * @param payload with commissionId and articleId
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    @Action
    public async removeArticle(payload: {
        commissionId: number;
        articleId: number;
    }) {
        if (!AuthModule.isAuthenticated) {
            this.removeArticleFromLocalCart(payload);
            this.saveCart();
        } else {
            const response = await axios.post<
                APIResponse<CartCommission<ArticleType>>
            >("/shoppingcart/delete", payload);

            if (response.data.status === "error") {
                throw new Error(response.data.message || "unknownError");
            }
        }
    }

    /**
     * Removes the given commission from the cart
     * When the user is authenticated the commission will be removed from online cart
     * Else the commission will be removed from offline cart
     *
     * @param commissionId commission id
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    @Action
    public async removeCommission(commissionId: number) {
        if (!AuthModule.isAuthenticated) {
            this.removeCommissionFromLocalCart(commissionId);
            this.saveCart();
        } else {
            const response = await axios.post<
                APIResponse<CartCommission<ArticleType>>
            >("/shoppingcart/group/delete/" + commissionId);

            if (response.data.status === "error") {
                throw new Error(response.data.message || "unknownError");
            }
        }
    }

    /**
     * When the user is authenticated the online cart will be returned
     * Else the offline cart will be returned
     *
     * @returns cart object
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    @Action
    public async getCart(): Promise<Cart<ArticleType>> {
        if (!AuthModule.isAuthenticated) {
            return this._cart;
        }

        const response = await axios.get<
            APIResponse<CartCommission<ArticleType>[]>
        >("/shoppingcart/get");
        if (response.data.status === "error") {
            throw new Error(response.data.message || "unknownError");
        }

        return { commissions: response.data.data };
    }
}

// Export CartModule
export default getModule(CartModule);

// Export types
export * from "./types";
